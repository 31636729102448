
import Study from '@/models/Study'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

@Options({
  methods: mapActions(['getStudies']),
  computed: mapGetters(['studies'])
})
export default class Studies extends Vue {
  studies!: Study[]

  // Actions
  getStudies!: (id: number) => Promise<void>

  created (): void {
    /*
    this.getStudies(Number(this.$route.params.id))
      .then(() => {
        console.log(this.studies)
      })
      .catch(() => {
        this.$router.push({ name: 'Logout' })
      })
  */
    console.log(this.studies)
  }
}
